import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from '@components/ProtectedRoute';
import { FullPageLoader } from '@components/Loader';
import { AuthProvider } from 'src/contexts/auth';

import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import CollectionAgentForm from 'src/pages/CollectionAgentForm';

const CallDetailsPage = lazy(() => import('src/pages/CallDetailsPage'));
const ScheduledCallDetailsPage = lazy(() => import('src/pages/ScheduledCallDetailsPage'));
const CallStatsPage = lazy(() => import('src/pages/CallStatsPage'));
const MLOpsPage = lazy(() => import('src/pages/MLOpsPage'));
const OpsManagementPage = lazy(() => import('src/pages/OpsManagementPage'));
const SpamManagerPage = lazy(() => import('src/pages/SpamManager'));
const GoogleLogin = lazy(() => import('src/pages/GoogleLogin'));
const DataUploaderPage = lazy(() => import('src/pages/DataUploaderPage'));

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="dashboard">
        <Route
          index
          element={
            <AuthProvider>
              <ProtectedRoute isPrivate={true}>
                <Suspense fallback={<FullPageLoader />}>
                  <Dashboard />
                </Suspense>
              </ProtectedRoute>
            </AuthProvider>
          }
        />
        <Route
          path=":id"
          element={
            <AuthProvider>
              <ProtectedRoute isPrivate={true}>
                <Suspense fallback={<FullPageLoader />}>
                  <CallDetailsPage />
                </Suspense>
              </ProtectedRoute>
            </AuthProvider>
          }
        />
      </Route>

      <Route
        path="scheduled/:id"
        element={
          <AuthProvider>
            <ProtectedRoute isPrivate={true}>
              <Suspense fallback={<FullPageLoader />}>
                <ScheduledCallDetailsPage />
              </Suspense>
            </ProtectedRoute>
          </AuthProvider>
        }
      />
      <Route
        path="/stats"
        element={
          <AuthProvider>
            <ProtectedRoute isPrivate={true}>
              <Suspense fallback={<FullPageLoader />}>
                <CallStatsPage />
              </Suspense>
            </ProtectedRoute>
          </AuthProvider>
        }
      />

      <Route
        path="/uploader"
        element={
          <AuthProvider>
            <ProtectedRoute isPrivate={true}>
              <Suspense fallback={<FullPageLoader />}>
                <DataUploaderPage />
              </Suspense>
            </ProtectedRoute>
          </AuthProvider>
        }
      />

      <Route
        path="/mlops"
        element={
          <AuthProvider>
            <ProtectedRoute isPrivate={true}>
              <Suspense fallback={<FullPageLoader />}>
                <MLOpsPage />
              </Suspense>
            </ProtectedRoute>
          </AuthProvider>
        }
      />

      <Route
        path="/"
        element={
          <AuthProvider>
            <ProtectedRoute isPrivate={true}>
              <Suspense fallback={<FullPageLoader />}>
                <CollectionAgentForm />
              </Suspense>
            </ProtectedRoute>
          </AuthProvider>
        }
      />
      <Route
        path="/spam"
        element={
          <AuthProvider>
            <ProtectedRoute isPrivate={true}>
              <Suspense fallback={<FullPageLoader />}>
                <SpamManagerPage />
              </Suspense>
            </ProtectedRoute>
          </AuthProvider>
        }
      />
      <Route
        path="/ops"
        element={
          <AuthProvider>
            <ProtectedRoute isPrivate={true}>
              <Suspense fallback={<FullPageLoader />}>
                <OpsManagementPage />
              </Suspense>
            </ProtectedRoute>
          </AuthProvider>
        }
      />
      <Route
        path="/google-login"
        element={
          <Suspense fallback={<FullPageLoader />}>
            <GoogleLogin />
          </Suspense>
        }
      />
      <Route
        path="login"
        element={
          <ProtectedRoute isPrivate={false}>
            <Suspense fallback={<FullPageLoader />}>
              <Login />
            </Suspense>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
