type Props = {
  icon: JSX.Element;
  onClick: () => void;
  title: string;
};

export default function NavBarItem({ onClick, icon, title }: Props) {
  return (
    <div
      onClick={onClick}
      className="p-2 w-full flex justify-start items-center gap-x-2 cursor-pointer hover:bg-blue-100 rounded-xl"
    >
      {icon}
      <div className="text-sm font-medium">{title}</div>
    </div>
  );
}
