import { PrimaryButton, SecondaryButton } from '@components/Buttons';
import { CenterModalWithHeader } from '@components/Modal/CenterModalWithHeader';
import { TextField } from '@components/TextField';
import { FilterIcon, XCircleIcon } from '@heroicons/react/outline';
import { IconButton } from '@mui/material';
import { useRef, useState } from 'react';
import { filterInvalidPhoneNumbers, addExtension } from './phoneFilter.utils';

type Props = {
  onFilterChange: (filters: any) => void;
  prevFilter: string[];
};

export default function UserPhoneFilter({ onFilterChange, prevFilter }: Props) {
  const iconRef = useRef(null);

  const [selectedFilters, setSelectedFilters] = useState<string[]>(prevFilter || []);
  const [showFilter, toggleShowFilter] = useState<boolean>(false);
  const [val, setVal] = useState<string>('');

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onAddFilter();
    }
  };

  const onApplyFilters = () => {
    onFilterChange({
      phone_numbers: [...selectedFilters],
    });
  };

  const onClearFilter = () => {
    setSelectedFilters([]);
    onFilterChange({
      phone_numbers: [],
    });
    setVal('');
  };

  const onRemove = (val: string) => {
    const index = selectedFilters?.findIndex((l) => l === val);
    if (index !== -1) {
      let updatedFilters = [];
      updatedFilters = selectedFilters.filter((i) => i !== val);
      setSelectedFilters([...updatedFilters]);
    }
  };

  const onAddFilter = () => {
    const hasMultiple = val.includes(',');
    if (hasMultiple) {
      const newNumbers = [];
      const values = val.split(',');
      const validValues = filterInvalidPhoneNumbers(values);
      for (let i = 0; i < validValues.length; i++) {
        const updatedNumber = addExtension(validValues[i]);
        const index = selectedFilters?.findIndex((l) => l === updatedNumber);
        if (index === -1) {
          newNumbers.push(updatedNumber);
        }
      }
      setSelectedFilters([...selectedFilters, ...newNumbers]);
    } else {
      const updatedVal = addExtension(val);
      setSelectedFilters([...selectedFilters, updatedVal]);
    }
    setVal('');
  };

  const handleChange = (val: string) => {
    setVal(val);
  };

  return (
    <>
      <div className="relative">
        <IconButton ref={iconRef} onClick={() => toggleShowFilter(!showFilter)}>
          <FilterIcon className="w-5 h-5 text-primaryButton" />
        </IconButton>

        {selectedFilters.length > 0 && (
          <div className="absolute top-0 right-0 w-4 h-4 bg-red-500 rounded-full flex justify-center items-center text-white text-[10px]">
            {selectedFilters.length}
          </div>
        )}
      </div>

      <CenterModalWithHeader
        title="Filter Phone numbers"
        shouldClose
        isVisible={showFilter}
        className="w-[650px] max-h-[500px]"
        onClose={() => toggleShowFilter(false)}
      >
        <div className="flex flex-col justify-between items-start gap-y-2 p-4 w-full max-h-[500px]">
          <div className="flex flex-col justify-start items-start gap-y-3 w-full overflow-auto">
            <div className="flex flex-col gap-2 w-full">
              {/* <div className="text-lg font-medium">Phone number</div> */}
              <TextField
                value={val}
                placeholder="Enter one of more phone numbers"
                onChange={(e) => handleChange(e.target.value)}
                onKeyDown={(e) => handleKeyPress(e)}
              />
            </div>
            {selectedFilters.length > 0 && (
              <div className="flex flex-col gap-y-2 mt-2">
                <div className="text-sm">Added Phone numbers ({selectedFilters.length})</div>
                <div className="flex flex-wrap items-center gap-2 max-h-[300px] overflow-y-scroll">
                  {selectedFilters.map((phone) => {
                    return (
                      <div className="flex justify-between items-center p-0 pl-1  border rounded-2xl border-gray-500 hover:bg-blue-100">
                        <div className="text-xs font-medium">{phone}</div>
                        <IconButton onClick={() => onRemove(phone)}>
                          <XCircleIcon className="w-5 h-5" />
                        </IconButton>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          <div className="w-full flex justify-end items-center mt-2 gap-x-2">
            <SecondaryButton disabled={selectedFilters.length === 0} propClass="w-24" onClick={onClearFilter}>
              Clear all
            </SecondaryButton>
            <PrimaryButton disabled={selectedFilters.length === 0} propClass="w-10" onClick={onApplyFilters}>
              Apply
            </PrimaryButton>
          </div>
        </div>
      </CenterModalWithHeader>
    </>
  );
}
