import { lazy, Suspense, useContext, useEffect, useMemo, useRef, useState } from 'react';
//components
import { ActualCalls } from 'src/container/DashboardLogs';
import { Dropdown } from '@components/Dropdown';
import { TopNavbar } from '@components/TopNavbar';
//contexts
import { AuthContext } from 'src/contexts/auth';
import { AuthContextInterface } from 'src/contexts/auth/AuthContext';
//utils
import { twMerge } from 'tailwind-merge';
import { getDataFromStorage, setDataInStorage } from '@utils/storageUtils';
//types
import { CompanyNode } from 'src/typings/user.types';

const FutureScheduledCalls = lazy(() => import('src/container/DashboardLogs/FutureScheduledCalls'));
const GarbageCalls = lazy(() => import('src/container/DashboardLogs/GarbageCall'));
const ScheduledCalls = lazy(() => import('src/container/DashboardLogs/ScheduledCalls'));

const tabs = [
  { id: 0, name: 'Actual Calls' },
  { id: 1, name: 'Future Scheduled Calls' },
  { id: 2, name: 'All Scheduled Calls' },
  { id: 3, name: 'Ongoing Calls' },
];

export default function Dashboard() {
  const prevCompany = getDataFromStorage('company');
  const prevTab = getDataFromStorage('prevTab');
  const [selectedCompany, setSelectedCompany] = useState<CompanyNode | null>(prevCompany || null);
  const [selectedIndex, setSelectedIndex] = useState<number>(prevTab || 0);
  const tabNodeRefArr = useRef<HTMLDivElement[]>([]);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { isAdmin, companyList } = useContext(AuthContext) as AuthContextInterface;

  const onScrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleCompanyChange = (val: any) => {
    if (val) {
      setSelectedCompany(val);
      setDataInStorage('company', val);
    }
  };

  useEffect(() => {
    if (companyList && companyList.length === 1) {
      handleCompanyChange(companyList[0]);
    }
  }, [companyList]);

  const left =
    tabNodeRefArr.current[selectedIndex]?.getBoundingClientRect().x -
      tabNodeRefArr.current[0]?.getBoundingClientRect().x || 0;

  const width = tabNodeRefArr.current[selectedIndex]?.getBoundingClientRect().width;

  const companyDropdown = useMemo(() => {
    return isAdmin ? [{ id: 'all', name: 'All' }, ...companyList] : companyList;
  }, [isAdmin, companyList]);

  return (
    <div className="flex flex-col items-center justify-start w-full h-screen">
      <TopNavbar showBackButton={false} pageTitle="Dashboard" />

      <div
        className="w-full h-[calc(100%-56px)] px-4 sm:px-10 md:px-20 py-4 md:py-10 overflow-y-auto max-h-screen bg-[#F8F8FA]"
        ref={scrollRef}
      >
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center gap-x-2 relative w-full">
            {tabs?.map((tab, index: number) => {
              const isSelected = selectedIndex === index;
              return (
                <div
                  key={`apiResponse-${index}`}
                  className={twMerge(`cursor-pointer p-2 font-medium`, isSelected ? 'py-2' : '')}
                  ref={(element: HTMLDivElement) => (tabNodeRefArr.current[index] = element)}
                  onClick={() => {
                    setSelectedIndex(index);
                    setDataInStorage('prevTab', index);
                    setDataInStorage('page', 1);
                  }}
                >
                  {tab.name}
                </div>
              );
            })}
            <div
              className="h-1 bg-primaryButton absolute -bottom-1 left-0 rounded-lg transition-[left] ease-in-out duration-200"
              style={{ left, width: width || '100px' }}
            ></div>
          </div>

          <div>
            <Dropdown
              mandatory
              placeholder="Select Company"
              options={companyDropdown}
              onSelect={handleCompanyChange}
              label="Company"
              defaultValue={selectedCompany?.name || prevCompany?.name}
            />
          </div>
        </div>

        <>
          {selectedIndex === 0 && (
            <ActualCalls isAdmin={isAdmin} company={selectedCompany} onScrollToTop={onScrollToTop} />
          )}
          {selectedIndex === 1 && (
            <Suspense fallback={<div>Loading...</div>}>
              <FutureScheduledCalls company={selectedCompany} onScrollToTop={onScrollToTop} />
            </Suspense>
          )}
          {selectedIndex === 2 && (
            <Suspense fallback={<div>Loading...</div>}>
              <ScheduledCalls company={selectedCompany} onScrollToTop={onScrollToTop} />
            </Suspense>
          )}
          {selectedIndex === 3 && (
            <Suspense fallback={<div>Loading...</div>}>
              <GarbageCalls company={selectedCompany} onScrollToTop={onScrollToTop} />
            </Suspense>
          )}
        </>
      </div>
    </div>
  );
}
