import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ServerException } from '../utils';
import { EventEmitter } from '@utils/Events';
import { Client, clientFactory } from './clientFactory';
import { removeGitAccessToken, removeGitRefreshToken } from '@utils/storageUtils';

export function createAxiosClient(clientType: Client, timeout: number): AxiosInstance {
  const clientInstance = clientFactory.createClient(clientType);

  const axiosClient: AxiosInstance = axios.create({
    withCredentials: false,
    baseURL: clientInstance.getBaseUrl(),
    timeout,
  });

  axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  // All request will wait 100 seconds! before timeout
  // axiosClient.defaults.timeout = 100000;
  // axiosClient.defaults.withCredentials = true;

  axiosClient.interceptors.request.use(
    (request: AxiosRequestConfig) => {
      request.headers = request.headers ?? {};
      const token = clientInstance.getAccessToken();

      if (token && clientType === Client.CORE) {
        request.headers['Authorization'] = `${token}`;
      } else if (token && clientType === Client.GOOGLE) {
        request.headers['Authorization'] = `Bearer ${token}`;
      }
      return request;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    },
    { synchronous: true },
  );

  axiosClient.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async (error) => {
      // API call failure due to network error
      if (error?.request?.status === 0) {
        return Promise.reject({
          data: { detail: [{ type: 'Network Error', msg: error.message }] },
        });
        // throw new ServerException({
        //   message: error?.message,
        //   statusCode: error?.request?.status,
        // });
      }

      /**
       * Error message
       */
      let errorMessage;
      if (typeof error?.response?.data === 'string') {
        errorMessage = error.response.data;
      } else if (error?.response?.data?.error?.message) {
        errorMessage = error.response.data.error.message;
      } else if (error?.response?.data?.message) {
        errorMessage = error.response.data.message;
      } else if (error?.response?.data?.detail) {
        errorMessage = error.response.data.detail;
      } else {
        errorMessage = 'Something went wrong!';
      }
      const responseHeaders = error?.response?.headers ?? {};
      if (error.response.status === 401) {
        /**
         * If refresh token is present but it is invalid and api returns 401 error,
         * then we do a force logout.
         */

        if (error.config.url === '/api/token/') {
          /**
           * In case if user is on login page and trying to login with invalid password, token API throws 401.
           * Clear existing refresh token and access token to avoid infinite refresh token api call.
           * */
          EventEmitter.emit('forceLogout');
        }
        if (error.config.url === '/api/token/refresh/') {
          // Clear cookies, reset user info and navigate to login
          EventEmitter.emit('forceLogout');
        } else if (error.config.url === '/core/api/users/refresh-git-token/') {
          removeGitAccessToken();
          removeGitRefreshToken();
          throw error;
        } else if (error.config.url === '/api/social-login/') {
          throw error;
        }

        const refreshToken = clientInstance.getRefreshToken();

        if (refreshToken) {
          try {
            await clientInstance.fetchNewAccessToken(refreshToken);
            // Making Original API call again, after refreshing token
            return axiosClient.request(error.config);
          } catch (error) {
            throw error;
          }
        } else {
          if (clientType === Client.CORE) {
            // Clear cookies, reset user info and navigate to login
            EventEmitter.emit('forceLogout');
          }
        }
      }
      return Promise.reject(error.response);
      // throw new ServerException({
      //   message: errorMessage,
      //   statusCode: error?.response?.status,
      //   headers: responseHeaders,
      //   meta: {
      //     errorMessage,
      //   },
      // });
    },
  );
  return axiosClient;
}
