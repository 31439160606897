function validateIndianPhoneNumber(phone: string): boolean {
  const regex = /^(?:\+91[\s-]?)?[6-9]\d{9}$/;
  return regex.test(phone);
}

export const addExtension = (val: string): string => {
  try {
    const trimmedVal = val.trim();
    const updatedVal = trimmedVal.includes('+91') ? trimmedVal : `+91${trimmedVal}`;
    return updatedVal;
  } catch (error) {
    return '';
  }
};

export const filterInvalidPhoneNumbers = (values: string[]): string[] => {
  try {
    const result = [];
    for (let i = 0; i < values.length; i++) {
      const val = values[i].trim();
      if (validateIndianPhoneNumber(val)) {
        result.push(val);
      }
    }
    return result;
  } catch (error) {
    return [];
  }
};
