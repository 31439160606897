import { PrimaryButton, SecondaryButton } from '@components/Buttons';
import { TextField } from '@components/TextField';
import { FilterIcon, XCircleIcon } from '@heroicons/react/outline';
import { IconButton, Popover } from '@mui/material';
import { useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

const quickFilter = [
  {
    val: 7,
    name: '7 days',
  },
  {
    val: 30,
    name: '30 days',
  },
  {
    val: 60,
    name: '60 days',
  },
];

type Props = {
  onFilterChange: (filters: any) => void;
  prevFilter: number;
};

export default function UserDPDFilter({ onFilterChange, prevFilter }: Props) {
  const iconRef = useRef(null);
  const [selectedDPD, setSelectedDPD] = useState<number | null>(prevFilter || null);
  const [showFilter, toggleShowFilter] = useState<boolean>(false);
  const [val, setVal] = useState<string | number>('');

  const onAddFilter = () => {
    setSelectedDPD(val);
    onFilterChange({
      dpd: val,
    });
    setVal('');
  };

  const onRemove = () => {
    setVal('');
    setSelectedDPD(null);
    onFilterChange({
      dpd: null,
    });
  };

  const handleChange = (val: string) => {
    setVal(val);
  };

  const disableClearBtn = val === '' ? (selectedDPD !== null ? false : true) : false;

  return (
    <>
      <div className="relative">
        <IconButton ref={iconRef} onClick={() => toggleShowFilter(!showFilter)}>
          <FilterIcon className="w-5 h-5 text-primaryButton" />
        </IconButton>

        {selectedDPD !== null && (
          <div className="absolute top-0 right-0 w-4 h-4 bg-red-500 rounded-full flex justify-center items-center text-white text-[10px]">
            {1}
          </div>
        )}
      </div>

      <Popover
        open={showFilter}
        onClose={() => toggleShowFilter(false)}
        anchorEl={iconRef.current}
        anchorOrigin={{
          vertical: 50, // 50 pixels offset from the top of the page
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="flex flex-col gap-y-2 p-4 w-[250px]">
          <div className="flex flex-col gap-y-3 w-full overflow-auto">
            <div className="flex justify-between items-center">
              <div className="text-lg font-medium">Days Past Due (DPD)</div>
              {selectedDPD && (
                <div className="flex justify-end items-center pl-2 rounded-xl border bg-primaryButton text-white">
                  <div className="text-sm">{`>${selectedDPD}s`}</div>
                  <IconButton onClick={() => onRemove()}>
                    <XCircleIcon className="w-4 h-4 text-white" />
                  </IconButton>
                </div>
              )}
            </div>
            <TextField value={val} placeholder="Enter DPD..." onChange={(e) => handleChange(e.target.value)} />
            <hr />
            <div className="flex justify-start items-center flex-wrap gap-2">
              {quickFilter.map((filter) => {
                const isSelected = val === filter.val;
                return (
                  <div
                    onClick={() => setVal(filter.val)}
                    key={filter.val}
                    className={twMerge(
                      'flex justify-center items-center px-3 py-1 rounded-xl border text-sm hover:bg-blue-50 cursor-pointer transition-colors ease-in-out',
                      isSelected ? 'bg-primaryButton text-white' : '',
                    )}
                  >
                    {filter.name}
                  </div>
                );
              })}
            </div>
            <div className="w-full flex justify-between items-center mt-2">
              <SecondaryButton disabled={disableClearBtn} propClass="w-20" onClick={onRemove}>
                Clear
              </SecondaryButton>
              <PrimaryButton disabled={val === ''} propClass="w-10" onClick={onAddFilter}>
                Apply
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
}
