export enum Languages {
  English = 'en-IN',
  Hindi = 'hi-IN',
  Indonesian = 'id-ID',
  Tamil = 'ta-IN',
}

export const languageOptions = [
  { name: 'Hindi', id: Languages.Hindi },
  { name: 'English', id: Languages.English },
  { name: 'Indonesian', id: Languages.Indonesian },
  { name: 'Tamil', id: Languages.Tamil },
];
