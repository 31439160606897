import ms from 'ms';
import { useQuery } from 'react-query';
import { CallLog, CallLogDetails } from 'src/typings/call.types';
import { callLogs } from 'src/queryClient/queryKeys';
import { CoreAPIs } from '../../../api';

type Params = {
  callId: CallLog['id'];
  enabled: boolean;
};

const getCallLogDetails = async (params: Params): Promise<CallLogDetails> => {
  const data = await CoreAPIs.getCallLogDetails(params.callId);
  return data;
};

export const useGetCallLogDetails = ({ callId, enabled }: Params) => {
  const result = useQuery<CallLogDetails, Error>([callLogs, callId], () => getCallLogDetails({ callId }), {
    staleTime: ms('20min'),
    enabled: enabled && Boolean(callId),
    refetchOnWindowFocus: false,
    retry: false,
  });
  return result;
};
