import { Skeleton } from '@mui/material';

export default function DashboardLoader() {
  return (
    <div className="rounded-sm bg-white flex flex-col gap-2 p-4 w-full mt-4">
      <div className="flex justify-between items-center">
        <Skeleton width={150} height={50} />
        <Skeleton width={500} height={60} />
      </div>
      <div className="flex justify-between gap-x-10 items-center">
        <Skeleton width={100} height={50} />
        <Skeleton width={100} height={50} />
        <Skeleton width={100} height={50} />
        <Skeleton width={100} height={50} />
        <Skeleton width={100} height={50} />
        <Skeleton width={100} height={50} />
        <Skeleton width={100} height={50} />
      </div>
      <Skeleton width="100%" height={50} />
      <Skeleton width="100%" height={50} />
      <Skeleton width="100%" height={50} />
      <Skeleton width="100%" height={50} />
      <Skeleton width="100%" height={50} />
      <Skeleton width="100%" height={50} />
      <Skeleton width="100%" height={50} />
      <Skeleton width="100%" height={50} />
    </div>
  );
}
